<template>
  <div>
    <v-card :loading="$store.state.loading" elevation="0" class="transparent">
      <v-card-title>
        <h3>اصوات الاشعارات</h3>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openAddDialog()">اضافة صوت</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :ripple="false" :items="designs" hide-default-footer
              :loading="$store.state.loading" no-data-text="لا يوجد" loading-text="جار التحميل...">
              <template v-slot:item.created="{ item }">
                {{ $service.formatDate(new Date(item.createdAt), false) }}
              </template>
              <template v-slot:item.audio="{ item }">
                <audio :src="$service.url + item.audio.url" controls class="mt-2">
                  <source :src="$service.url + item.audio.url">
                </audio>
              </template>

              <template v-slot:item.selected="{ item }">
                <template v-if="rest.selected_sound">
                  <v-chip label :outlined="rest.selected_sound.id != item.id">
                    {{ rest.selected_sound.id != item.id ? 'غير رئيسي' : 'تم التحديد كرئيسي' }}
                  </v-chip>
                </template>
                <v-chip label v-else outlined>
                   غير رئيسي
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                  <v-btn color="primary" @click="setAsMain(item)" outlined>
                    <v-icon>mdi-pencil</v-icon>
                    تحديد كرئيسي
                  </v-btn>
                  <v-btn color="error" text @click="deleteItem(item.id, item.title)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-center">
              <v-pagination v-model="options.pagination.page"
                :length="parseInt(total / options.pagination.pageSize) + 1" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Add />
  </div>
</template>
<script>
import Add from "./Add.vue";
export default {
  components: { Add },
  data() {
    return {
      designs: [],
      total: 1,
      rest: {
        selected_sound:{
          id:0
        }
      },
      headers: [
        { text: "الملف", value: "audio" },
        { text: "تم التحديد", value: "selected" },
        { text: "التاريخ", value: "created" },
        { text: "الاجراءات", value: "actions" },
      ],
      options: {
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
        },
        populate: "*",
        pagination: {
          page: 1,
          pageSize: 15,
        },
      },
    };
  },

  async created() {
    this.getnews();
    this.getInfo()
    this.$root.$on("refresh", () => {
      this.getnews();
      this.getInfo()

    });
  },
  methods: {
    async getInfo() {
      const res = await this.$http.get(
        "/resturants/" + this.$store.state.resturant.id,
        {
          params: {
            populate: {
              selected_sound: {
                populate: '*'
              }
            },
          },
          paramsSerializer: this.$service.qsParams,
        }
      );
      this.rest = res.data.data
    },
    getnews() {
      this.$store.commit("setLoading", true);
      this.$http
        .get("/sounds", {
          params: this.options,
          paramsSerializer: this.$service.qsParams,
        })
        .then((response) => {
          this.designs = response.data.data;
          this.total = response.data.meta.pagination.total;
          this.$store.commit("setLoading", false);
        });
    },

    openAddDialog() {
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "add";
    },
    async setAsMain(item) {
      this.$store.commit("setLoading", true);
      await this.$http.put("/resturants/" + this.$store.state.resturant.id, {
        data: {
          selected_sound: item.id
        },
      });
      this.$store.commit("UPDATE_SNACKBAR_MESSAGE", {
        message: "تمت العملية بنجاح",
        color: "success",
      });
      this.$store.commit("UPDATE_SNACKBAR", true);
      this.getInfo();
      this.$store.commit("setLoading", false);

    },
    deleteItem(id, title) {
      title;
      this.$store.commit("setLoading", true);
      this.$http.put("/sounds/" + id, {
        data: {
          publishedAt: null
        }
      })
      this.designs = this.designs.filter(item => item.id != id)
      // this.getnews()
      this.$store.commit("setLoading", false);
    }
  },
  watch: {
    options: {
      handler() {
        this.getnews();
      },
      deep: true,
    },
  },
};
</script>
