<template>
  <div>
    <v-dialog
      v-model="$store.state.dialog"
      persistent
      :overlay="false"
      max-width="1080px"
      transition="dialog-transition"
    >
      <v-card flat>
        <v-card-title>
          <span class="headline">
            {{ $store.state.dialogType === "add" ? "اضافة" : "تعديل" }}
            صوت اشعار
          </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="$store.state.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-form v-model="valid">
            <v-row v-if="!$store.state.loading">
              <v-col cols="12">
                <v-file-input v-model="file" outlined label="الصوت"></v-file-input>
              </v-col>
              <v-col cols="12" md="12">
                <div class="d-flex justify-end">
                  <v-btn :disabled="!valid" large @click="add" color="primary">
                    <v-icon left>mdi-content-save</v-icon>
                    حفظ
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <div v-else class="d-flex justify-center mt-10 pt-10">
              <v-progress-circular
                indeterminate
                size="60"
              ></v-progress-circular></div
          ></v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      file: null,
      inputs: {
        data: {
          audio: "",
          resturant: this.$store.state.resturant.id,
        },
      },
      loading: false,
      text: "",
      valid: false,
      isEdit: false,
    };
  },
  methods: {
    async add() {
      this.$store.commit("setLoading", true);
      if (this.file != null) {
        let res = await this.$service.uploadFile(this.file);
        this.inputs.data.audio = res.id;
      }
      if (this.$store.state.dialogType == "edit") {
        await this.$http.put(
          "/sounds/" + this.$store.state.item.id,
          this.inputs
        );
      } else {
        await this.$http.post("/sounds", this.inputs);
      }

      this.$store.commit("setLoading", false);
      this.$root.$emit("refresh");
      this.$store.state.dialog = false;
    },
  },
  mounted() {
    // listen to the event
    this.$root.$on("fill-fields", (data) => {
      Object.keys(data).forEach((key) => {
        this.inputs.data[key] = data[key];
      });
      if (data.audio != null) {
        this.inputs.data.audio = data.photo.id;
        this.file = new File([data.photo], data.photo.name, {
          type: data.photo.type,
        });
      }
    });
  },
  watch: {
    "$store.state.dialog"(val) {
      if (val == false) {
        this.inputs.data = {
          audio: "",
          resturant: this.$store.state.resturant.id,
        };
      }
    },
  },
};
</script>
