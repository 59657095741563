var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"transparent",attrs:{"loading":_vm.$store.state.loading,"elevation":"0"}},[_c('v-card-title',[_c('h3',[_vm._v("اصوات الاشعارات")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openAddDialog()}}},[_vm._v("اضافة صوت")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"ripple":false,"items":_vm.designs,"hide-default-footer":"","loading":_vm.$store.state.loading,"no-data-text":"لا يوجد","loading-text":"جار التحميل..."},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.createdAt), false))+" ")]}},{key:"item.audio",fn:function(ref){
var item = ref.item;
return [_c('audio',{staticClass:"mt-2",attrs:{"src":_vm.$service.url + item.audio.url,"controls":""}},[_c('source',{attrs:{"src":_vm.$service.url + item.audio.url}})])]}},{key:"item.selected",fn:function(ref){
var item = ref.item;
return [(_vm.rest.selected_sound)?[_c('v-chip',{attrs:{"label":"","outlined":_vm.rest.selected_sound.id != item.id}},[_vm._v(" "+_vm._s(_vm.rest.selected_sound.id != item.id ? 'غير رئيسي' : 'تم التحديد كرئيسي')+" ")])]:_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(" غير رئيسي ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.setAsMain(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" تحديد كرئيسي ")],1),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.deleteItem(item.id, item.title)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.pagination.pageSize) + 1},model:{value:(_vm.options.pagination.page),callback:function ($$v) {_vm.$set(_vm.options.pagination, "page", $$v)},expression:"options.pagination.page"}})],1)])],1)],1)],1),_c('Add')],1)}
var staticRenderFns = []

export { render, staticRenderFns }